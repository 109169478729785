<template>
	<div class="infobox" :class="{'info':msgType==='info', 'alert':msgType==='alert'}">
		<div class="icons">
			
			<svg id="icon-info" class="icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" v-if="msgType==='info'">
				<g>
					<circle cx="24.9999" cy="25" r="23.5" fill="#39b54a"  />
					<path d="M25,3c12.1308,0,22,9.8692,22,22s-9.8692,22-22,22S3,37.1308,3,25,12.8692,3,25,3m0-3C11.1929,0,0,11.1929,0,25s11.1929,25,25,25,25-11.1929,25-25S38.8071,0,25,0h0Z" fill="#FFF"/>
				</g>
				<line x1="24.9517" y1="36.9802" x2="24.9517" y2="24.0434" fill="#39b54a" stroke="#FFF" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5" />
				<circle cx="24.9517" cy="15.0698" r="3.218" fill="#FFF" />
			</svg>

			<svg id="icon-alert" class="icon" xmlns="http://www.w3.org/2000/svg" width="54.8812" height="49.4281" viewBox="0 0 54.8812 49.4281" v-if="msgType==='alert'">
				<g>
					<path d="M7.1693,47.9282c-2.0459,0-3.8779-1.0576-4.9004-2.8291-1.0229-1.7725-1.0229-3.8877-.0005-5.6592L22.5399,4.329c1.0229-1.7715,2.855-2.8291,4.9009-2.8291s3.8779,1.0576,4.9004,2.8291l20.2715,35.1108c1.0225,1.7715,1.0225,3.8877-.001,5.6592-1.0225,1.7715-2.8545,2.8291-4.8994,2.8291H7.1693Z" fill="#F00"/>
					<path d="M27.4406,3c1.5034,0,2.8497,.7773,3.6014,2.0793l20.2714,35.1111c.7517,1.302,.7517,2.8566,0,4.1585-.7517,1.302-2.098,2.0792-3.6014,2.0792H7.1692c-1.5034,0-2.8497-.7773-3.6014-2.0792-.7517-1.302-.7517-2.8566,0-4.1585L23.8392,5.0793c.7517-1.302,2.098-2.0793,3.6014-2.0793m0-3c-2.4109,0-4.8218,1.1931-6.1994,3.5793L.9697,38.6903c-2.7553,4.7723,.6888,10.7378,6.1994,10.7378H47.712c5.5106,0,8.9548-5.9654,6.1994-10.7378L33.64,3.5793c-1.3777-2.3862-3.7886-3.5793-6.1994-3.5793h0Z" fill="#fff"/>
				</g>
				<line x1="27.4406" y1="15.9921" x2="27.4406" y2="28.9289" fill="#F00" stroke="#FFF" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5" />
				<circle cx="27.4406" cy="37.9025" r="3.218" fill="#FFF"/>
			</svg>

		</div>
		<div class="box" role="dialog" aria-label="close dialog button">
			<div class="closeButton" @click="sendCloseSignal"></div>
			<slot name="text"></slot>
		</div>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Toaster",
		props: {
			msgType : {
				type: String, 
				required: false,
				default: "info"
			}
		},
		methods: {
			sendCloseSignal(){
				this.$emit('closeMe');
			},
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

	.infobox {

		position: fixed;
		top:50px;
		right:0;

		margin: 0 auto;
		display: grid;
		grid-template-columns: 40px 1fr;
		background: #fff;

		z-index:9999999999;

		transition: transform 0.4s ease-in-out;

		transform: translateX(110%);
		&.active {
			transform: translateX(0%);
		}


		.icon {		
			text-align:center;
			line-height:40px;
			font-size:25px;
		}

		.box {
			padding: 0.5em 1em;
			line-height:1.4em;
			position: relative;

			.close {
				position: absolute;
				right:10px;
				top:2px;
				height:1.5em;
				line-height:1.5em;
				width: 1.5em;
				text-align: center;
			}
		}

		&.info {
			.icon {
				color: #9c0;
			}
			.box {
				background: #0601;
			}
		}
		&.alert {
			.icon {
				color: #F33;
			}
			.box {
				background: #c002;
			}
		}

	}


</style>
